import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseApp } from "firebase/app";

export default class Logger {
    private readonly analytics: Analytics
    constructor(private readonly firebaseApp: FirebaseApp) {
        this.analytics = getAnalytics(firebaseApp);
    }

    log(eventName: Parameters<typeof logEvent>[1], eventParams?: Parameters<typeof logEvent>[2]) {
        logEvent(this.analytics, eventName, eventParams);
    }
}