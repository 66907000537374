import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "../../functions/src/config/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserCollection from "./collections/UserCollection";
import Logger from "./util/Logger";
import LoginController from "./controllers/LoginController";
import StagesController, {Stage} from "./controllers/StagesController";
import ClickHandler from "./util/ClickHandler";

const firebaseApp = initializeApp(firebaseConfig, "reademaillater-public");
const firestore = getFirestore(firebaseApp);
// connectFirestoreEmulator(firestore, "localhost", 8090)
const auth = getAuth(firebaseApp);
// connectAuthEmulator(auth, "http://localhost:9099");
const logger = new Logger(firebaseApp);
const userCollection = new UserCollection(firestore);
const stagesController = new StagesController(Stage.BEFORE_POCKET_AUTHENTICATION);
const loginController = new LoginController(logger, userCollection, stagesController, auth);
const clickHandler = new ClickHandler(logger, auth);

onAuthStateChanged(auth, loginController.authStateChanged.bind(loginController));

const loginWithPocketButton = document.getElementById("loginWithPocketButton");
loginWithPocketButton?.addEventListener("click", clickHandler.loginWithPocket.bind(clickHandler));

const checkoutButton = document.getElementById("checkoutButton");
checkoutButton?.addEventListener("click", () => {
    clickHandler.checkout(loginController.pocketUsername);
});

const emailAddressButton = document.getElementById("email-address-button");
emailAddressButton?.addEventListener("click", () => {
    clickHandler.copyToClipboard(loginController.emailAddress);
});

const customerPortalButton = document.getElementById("customer-portal-button");
customerPortalButton?.addEventListener("click", clickHandler.openCustomerPanel.bind(clickHandler));


if (document.location.search === "?pocket_redirect") {
    loginController.onPocketRedirect();
}
