export enum Stage {
    BEFORE_POCKET_AUTHENTICATION,
    POCKET_AUTHENTICATED_NO_PLAN,
    WAITING_FOR_CONFIRMATION_OF_PAYMENT,
    ACTIVE_SUBSCRIPTION
}

enum ElementId {
    LOGIN_WITH_POCKET_BTN = "loginWithPocketButton",
    POCKET_ACCOUNT_CONNECTED = "pocket-account-connected",
    PICK_YOUR_PLAN = "pick-your-plan",
    THANK_YOU_FOR_SUBSCRIBING = "thank-you-for-subscribing",
    WAIT_FOR_PAYMENT_TO_BE_PROCESSED = "wait-for-payment-to-be-processed",
    YOUR_PERSONAL_EMAIL_ADDRESS = "your-personal-email-address",
    CUSTOMER_PORTAL = "customer-portal"
}

export default class StagesController {

    private currentStage: Stage = Stage.BEFORE_POCKET_AUTHENTICATION;

    private readonly elementsPerStage: Record<Stage, ElementId[]> = {
        [Stage.BEFORE_POCKET_AUTHENTICATION]: [ElementId.LOGIN_WITH_POCKET_BTN],
        [Stage.POCKET_AUTHENTICATED_NO_PLAN]: [ElementId.POCKET_ACCOUNT_CONNECTED, ElementId.PICK_YOUR_PLAN],
        [Stage.WAITING_FOR_CONFIRMATION_OF_PAYMENT]: [ElementId.POCKET_ACCOUNT_CONNECTED, ElementId.THANK_YOU_FOR_SUBSCRIBING, ElementId.WAIT_FOR_PAYMENT_TO_BE_PROCESSED],
        [Stage.ACTIVE_SUBSCRIPTION]: [ElementId.POCKET_ACCOUNT_CONNECTED, ElementId.THANK_YOU_FOR_SUBSCRIBING, ElementId.YOUR_PERSONAL_EMAIL_ADDRESS, ElementId.CUSTOMER_PORTAL]
    }

    constructor(initialStage: Stage) {
        this.updateStage(initialStage);
    }

    private setStagesVisibility = () => {
        const elementIds = Object.values(ElementId);
        elementIds.forEach(elementId => {
            const element = document.getElementById(elementId);
            if (element !== null) {
                element.style.display = this.elementsPerStage[this.currentStage].includes(elementId) ? "inherit" : "none";
            }
        })
    }

    updateStage(newStage: Stage) {
        this.currentStage = newStage;
        this.setStagesVisibility();
    }
}