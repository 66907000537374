import { Auth, signInAnonymously } from "firebase/auth";
import loadStripeWithPublishableKey from "../config/stripe";
import isEmail from "./isEmail";
import setElementInnerText from "./setElementInnerText"
import Logger from "./Logger";
import { STRIPE_MONTHLY_PLAN, STRIPE_YEARLY_PLAN } from "env";

export default class ClickHandler {
    constructor(private readonly logger:Logger, private readonly auth: Auth) {

    }

    async loginWithPocket() {
        this.logger.log("login-with-pocket-btn-clicked");
        const userCredential = await signInAnonymously(this.auth);
        const token = await userCredential.user.getIdToken();
        this.logger.log("start-pocket-oauth");
        const response = await fetch("/start_pocket_oauth",
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        const jsonResponse = await response.json();
        document.location = jsonResponse.redirectUri;
        // document.cookie = "__session=" + token + ";max-age=3600";
        // document.location = "/start_pocket_oauth";
    }

    async openCustomerPanel() {
        this.logger.log("go-to-customer-panel");
        const userCredential = await signInAnonymously(this.auth);
        const token = await userCredential.user.getIdToken();
        const response = await fetch("/customer_portal",
            {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        const jsonResponse = await response.json();
        document.location = jsonResponse.sessionUrl;
    }

    async checkout(pocketUsername?: string) {
        if (pocketUsername === undefined) {
            throw new Error("Can not checkout without pocketUsername");
        }
        this.logger.log("click-checkout-button");
        const stripe = await loadStripeWithPublishableKey();
        if (stripe === null) {
            throw new Error("Stripe instance is null");
        }
        const stripeErrorContainer = await stripe.redirectToCheckout({
            items: [{ plan: this.getPickedPlan(), quantity: 1 }],
            successUrl: document.location.href + "?paid",
            cancelUrl: document.location.href + "?canceled",
            clientReferenceId: pocketUsername,
            customerEmail: isEmail(pocketUsername) ? pocketUsername : undefined
        });
        if (stripeErrorContainer.error) {
            this.logger.log("click-checkout-button-error");
            setElementInnerText("error-message", stripeErrorContainer.error.message ?? "An unknown error occured, please try again");
        }
    }

    private getPickedPlan() {
        const checkedPlanElement = document.querySelector("input[name=\"plan\"]:checked");
        if (checkedPlanElement === null) {
            throw new Error("No Plan has been checked");
        }
        const valueAttribute = checkedPlanElement.getAttribute("value");
        if (valueAttribute === null) {
            throw new Error("Checked plan has no value attribute");
        }
        switch (valueAttribute) {
        case "monthly":
            return STRIPE_MONTHLY_PLAN;
        case "yearly":
            return STRIPE_YEARLY_PLAN;
        default:
            throw new Error(`valueAttribute is of unknown value ${valueAttribute}`);
        }
    }

    async copyToClipboard(emailAddress?: string) {
        if (emailAddress === undefined) {
            throw new Error("Can not copy emailaddress when it is undefined");
        }
        this.logger.log("copy-to-clipboard");
        navigator.clipboard.writeText(emailAddress);
        
        // 7 is length of "Copied!"
        const numSpaces = emailAddress.length - 7;

        setElementInnerText("email-address-button",
            `${"&nbsp;".repeat(Math.floor(numSpaces / 2))}Copied!${"&nbsp;".repeat(Math.round(numSpaces / 2))}`, true);
        setTimeout(() => {
            setElementInnerText("email-address-button", emailAddress);
        }, 2000);
    }
}



