import { doc, getDoc, collection, CollectionReference, DocumentData, Firestore, QueryDocumentSnapshot, DocumentSnapshot } from "firebase/firestore";

export interface UserDocumentData extends DocumentData {
    email_hash?: string,
    subscriptionActive?: boolean,
    username: string
}

export default class UserCollection {
    private collectionReference: CollectionReference<UserDocumentData, DocumentData>;

    private readonly converter = {
        toFirestore(user: UserDocumentData): DocumentData {
            return {...user}
        },
        fromFirestore(
            snapshot: QueryDocumentSnapshot
        ): UserDocumentData {
            return snapshot.data() as UserDocumentData;
        }
    };

    constructor(private readonly firestore: Firestore) {
        this.collectionReference = collection(firestore, "users").withConverter(this.converter);
    }

    async get(uid: string): Promise<DocumentSnapshot<UserDocumentData,DocumentData>> {
        return getDoc(doc(this.collectionReference, uid));
    }
}


