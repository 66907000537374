import { Auth, User, signInAnonymously } from "firebase/auth";
import Logger from "../util/Logger";
import UserCollection, { UserDocumentData } from "../collections/UserCollection";
import setElementInnerText from "../util/setElementInnerText";
import { DocumentData, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import StagesController, { Stage } from "./StagesController";

export default class LoginController {

    pocketUsername?: string;
    emailAddress?: string;

    constructor(private readonly logger: Logger, private readonly userCollection: UserCollection, private readonly stagesController: StagesController, private readonly auth: Auth) {

    }

    async onPocketRedirect() {
        const userCredential = await signInAnonymously(this.auth);
        const token = await userCredential.user.getIdToken();
        const response = await fetch("/redirect_uri",
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        await response.json();
    }

    authStateChanged(user: User | null) {
        if (user) {
            this.logger.log("firebase-auth-logged-in");
            this.onUserHasLoggedIn(user);
        } else {
            this.logger.log("firebase-auth-logged-out");
        }
    }

    private async onUserHasLoggedIn(user: User) {
        const snapshotUser = await this.userCollection.get(user.uid);
        onSnapshot(snapshotUser.ref, this.onNext.bind(this));
        if (snapshotUser.exists()) {
            this.logger.log("user-has-logged-in-doc-exists");
        } else {
            this.logger.log("user-has-logged-in-doc-does-not-exist");
        }
    }

    private onNext(updatedDoc: DocumentSnapshot<UserDocumentData, DocumentData>) {
        let stage: Stage = Stage.BEFORE_POCKET_AUTHENTICATION;

        const user = updatedDoc.data();
        console.log(user);

        this.pocketUsername = user?.username;
        if (this.pocketUsername !== undefined) {
            setElementInnerText("username", this.pocketUsername);
            stage = Stage.POCKET_AUTHENTICATED_NO_PLAN;
        }

        const emailHash = user?.email_hash;
        if (emailHash !== undefined) {
            this.emailAddress = `${emailHash}@reademaillater.com`;
            setElementInnerText("email-address-button", this.emailAddress);
        }

        if (window.location.search === "?paid") {
            this.logger.log("query-paid");
            stage = Stage.WAITING_FOR_CONFIRMATION_OF_PAYMENT;
        }

        const subscriptionActive = user?.subscriptionActive;
        if (subscriptionActive) {
            stage = Stage.ACTIVE_SUBSCRIPTION;
        }

        this.stagesController.updateStage(stage); 
    }
}